<template>
    <div class="anDiv">
      <el-row class="anRow" :gutter="40" style="margin-top:20px" v-for="(item,i) in newList" :key="i">
          <el-col :span=5  class="anRowRight" >
            <div style="padding:20px 0;min-width:140px">
                <span style="font-size:48px;line-height:48px">{{item.date.substring(0,4)}}</span>
                <div class="anxie"></div>
                <div style="display:inline-block">
                    <div class="anDiv1">{{item.date.substring(4,6)}}月</div>
                    <div class="anDiv1">{{item.date.substring(6,8)}}</div>
                </div>
            </div>
          </el-col>
          <el-col :span=5 class="anRowCen">
              <img :src="item.imgUrl"/>
          </el-col>
          <el-col :span=14 class="anRowLeft">
              <div class="anDiv2">{{item.title}}</div>
              <div class="anDiv3">{{item.content}}</div>
              <div class="anMore" @click="moreClick(item)">查看更多 ></div>
          </el-col>
      </el-row>
      <el-row class="anRowFooter">
          <el-col :span=24>
              <el-pagination background  
              layout="prev, pager, next"  
              :total="count" 
              :page-size="5"
              @current-change=currentPageClick

              >
              </el-pagination>
          </el-col>
      </el-row>
    </div>
</template>
<script>
export default {
    name:"aboutNews",
    data(){
        return{
            listData:[],
            count:0,
            currentPage:1,
            newList:[]
        }
        
    },
    methods:{
        init(){
            let list=[
                {
                    id:"n1",
                    date:"20210324",
                    title:"Adobe 携手全球商业领袖成立国际顾问委员会，助力企业在数字经济时代蓬勃发展",
                    content:`
                    中国  北京一2021 年 3 月24 日一随着各行各业都在转变策略以适应数字经济时代的全新规则  Adobe 于近日宣布成立其首个国际顾问委员会，
                    进一步帮助客户进行业务革新并制定数字化转型策略，
                    以在客户体验为主导的新时代中脱颖而出Adobe所成立的国际顾问委员会汇集了六位专注于国际市场的领导者，
                    他们在B2B、B2C 和政府领域的革新和转型中具备非常丰富的领导经验。
                    `,
                    imgUrl:require("../../static/aboutNews1.png")
                },
                 {
                    id:"n2",
                    date:"20210122",
                    title:"2021欧特克年度设计峰会线上开启 全方位探索数字技术创新价值",
                    content:`
                    2021年1月22日 – 今天，以 “重塑无限 创想未来” 为主题的 2021欧特克年度设计峰会以数字化的形式成功举办，
                    来自大中华区的工程建设业、制造业、传媒和娱乐业的众多创新者、设计精英和业界专家相聚云端，共同分享数字化转型的现状与机遇，
                    洞察领先技术的应用实践与前景，为线上的近万名观众打造了一场收获颇丰的创新盛宴，
                    勾画出数字未来的美好愿景。
                    `,
                    imgUrl:require("../../static/aboutNews1.png")
                },
                 {
                    id:"n3",
                    date:"20210325",
                    title:"Chaos Cloud education packages released",
                    content:`
                    Discounts on Chaos Cloud credits for all educational customers, 
                    plus 10 Chaos Cloud credits included in V-Ray Education Collection student and educator licenses.
                    `,
                    imgUrl:require("../../static/aboutNews1.png")
                },
                 {
                    id:"n4",
                    date:"20210324",
                    title:"Minitab 发布 Minitab Engage™ 以加快想法产生、创新和业务转型",
                    content:`
                    2021 年 3 月 25 日，宾夕法尼亚州立大学 – 数据分析、预测分析和过程改进领域的市场领导者 Minitab, LLC 宣布推出 Minitab EngageTM，一款独一无二的解决方案，在设计上，该解决方案能够在从想法产生到执行的过程中启动、跟踪、管理和共享创新和改进计划。
                    Minitab Engage 通过提供用于启动和管理创造价值的项目和创新的独特平台，让组织能够加快数字化转型。
                    `,
                    imgUrl:require("../../static/aboutNews1.png")
                },
                 {
                    id:"n5",
                    date:"20210324",
                    title:"ENSCAPE 3.0 PRESS RELEASE",
                    content:`
                    Enscape Launches Redesign of Real-Time Rendering Solution for Architects to Enable Quick and Intuitive Visualisation. 
                    Update optimises design process for architectural and interior designs
                    `,
                    imgUrl:require("../../static/aboutNews1.png")
                }

            ]
            this.listData=list
            this.count=list.length

            this.currentPageClick(1)
        
        },
        currentPageClick(page){
            this.newList=[]
            // this.currentPage=page
            let start=(page-1)*5
            let list=[]
            for(let i=0;i<5;i++){
                if(start+i+1<=this.listData.length){
                    let item=this.listData[start+i]
                    list.push(item)
                }
                
            }
            this.newList=list
            this.$forceUpdate()
        },
       moreClick(row){
           this.$router.push({
               name:"aboutNewsInfo",
               query:{
                    id:row.id,
                    type:"news"
               }
           })
       }
    },
    created(){
        this.init()
    }
}
</script>
<style>
.anDiv{
    padding-top:60px;
}
.anRow{
    padding:20px 0;
    border:1px solid #DBE3EF
}
.anRow .anxie{
    height: 48px;
    display: inline-block;
    border-left: 1px solid #848484;
    transform: skew(-20deg);
    margin: 0 15px;
}
.anRow .anDiv1{
    font-size:14px;
    line-height: 24px;
}
.anRow .anRowRight{

text-align:center
}
.anRow .anRowCen{
    border-left:1px solid #DBE3EF; 
}
.anRow .anMore
{
    font-size:14px;
    color:#3C6AFB;
    margin-top: 20px;
    cursor:pointer;
}
.anRowLeft .anDiv2{
    height: 32px;
    font-size: 18px;
    color: #404756;
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
}
.anRowLeft .anDiv3{
    height: 26px;
    font-size: 14px;
    color: rgba(64, 71, 86, 0.7);
    line-height: 26px;
    white-space:nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top:20px

}
.anRowFooter{
    text-align: right;
    padding: 60px 0;
}
</style>
